import { Action, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { createApi, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { createLabel, getCamelCaseInsensitive, numberWithSpaces } from "../../utils/utils";
import { getAccessToken } from "./authSlice";
import { baseQuery, baseQueryWithReauth } from "./query";
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "@app/store/store";
import Car, { FavoriteCar, ImageList } from "@shared/lib/interfaces/car.interface";
import _ from "lodash";
import { ProductPriceUsdParams } from "@features/product/ProductPriceUsd/model/types";
import { configApi } from "@shared/lib/configApi";
import { convertToSafeUrl } from "@shared/lib/utils/convertToSafeUrl";
import { DEFAULT_PAGE_LIMIT } from "@components/Features/Catalog/catalogUtils";

const API = process.env.NEXT_PUBLIC_API_URL;

function isHydrateAction(action: Action): action is PayloadAction<AppState> {
    return action.type === HYDRATE;
}

export interface QueryOffersParams {
    city?: string;
    from_displacement?: number;
    to_displacement?: number;
    generationUuid?: string;
    from_horse_power?: number;
    to_horse_power?: number;
    from_mileage?: number;
    to_mileage?: number;
    brandUuid?: string;
    modelUuid?: string;
    from_owners_count?: number;
    to_owners_count?: number;
    body_type?: string;
    drive_type?: string;
    engine_type?: string;
    seller_type?: string;
    transmission_type?: string;
    wheel_type?: string;
    from_price?: number;
    to_price?: number;
    from_year?: number;
    to_year?: number;
    source_id?: number[];
    page?: number;
    limit?: number;
    created_at?: string | null;
    sort?: string;
    filters?: any;
    bottom_left?: string;
    top_right?: string;
    precision?: number;
    geo_hash?: string;
    hideNoImages?: boolean;
    cityAlias?: string;
    brandAlias?: string;
    modelAlias?: string;
    generation?: number;
    restyling?: number;
}

export const generateOffersParams = (params: QueryOffersParams): string => {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (value === undefined || value === null) return;
        const formattedKey = _.camelCase(key);

        if (Array.isArray(value)) {
            value.forEach((item) => {
                searchParams.append(`${formattedKey}[]`, item.toString());
            });
            return;
        }

        searchParams.append(formattedKey, value.toString());
    });

    return searchParams.toString() ? `?${searchParams.toString()}` : "";
};

export const offersApi = createApi({
    reducerPath: "offersApi",
    tagTypes: ["Offers", "Favorites", "MyOffers", "RecommendedOffers", "Banners"],
    baseQuery: baseQueryWithReauth,
    extractRehydrationInfo(action, { reducerPath }): any {
        if (isHydrateAction(action)) {
            return action.payload[reducerPath];
        }
    },
    endpoints: (builder) => ({
        getOffers: builder.query<any, QueryOffersParams>({
            queryFn: async (args, api, extraOptions) => {
                let queryString = "";
                if (args) {
                    queryString = generateOffersParams({
                        limit: DEFAULT_PAGE_LIMIT,
                        ...args
                    });
                }
                const url = `${API}/v1/offers${queryString}`;

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: url,
                        method: "GET"
                    },
                    api,
                    extraOptions
                );

                if (result.data) {
                    const options = result.data;
                    const modifiedOffers = options.data.offers.map((offer: Car) => ({
                        ...offer,
                        imagesList: offer.imagesList.map((image: ImageList) => ({
                            big: convertToSafeUrl(image.big),
                            small: convertToSafeUrl(image.small)
                        }))
                    }));

                    return {
                        data: {
                            offers: modifiedOffers,
                            aggregations: options.data.aggregations,
                            meta: options.meta,
                            banners: options.data.banners
                        }
                    };
                } else {
                    return { error: result.error as FetchBaseQueryError };
                }
            },
            providesTags: ["Offers"]
        }),
        getRecommendedOffers: builder.query<any, any>({
            queryFn: async (id, api, extraOptions) => {
                const url = `${API}/v1/recommendations/offers/${id}`;

                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: url,
                        method: "GET"
                    },
                    api,
                    extraOptions
                );

                if (result.data) {
                    const options = result.data;
                    const modifiedOffers = options.data.map((offer: Car) => ({
                        ...offer,
                        imagesList: offer.imagesList.map((image: ImageList) => ({
                            big: convertToSafeUrl(image.big),
                            small: convertToSafeUrl(image.small)
                        }))
                    }));
                    return { data: { offers: modifiedOffers, meta: options.meta } };
                } else {
                    return { error: result.error as FetchBaseQueryError };
                }
            },
            providesTags: ["RecommendedOffers"]
        }),
        getOffer: builder.query<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${API}/v1/offers/${args}`
                    },
                    api,
                    extraOptions
                );

                return result.data && result.data?.data
                    ? {
                          data: transformOfferApiData(result.data.data, true) as Car
                      }
                    : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["Offers"]
        }),
        addToFavorites: builder.mutation<any, any>({
            queryFn: async (args, api, extraOptions) => {
                try {
                    const offerUuid = args.id;
                    const TOKEN = getAccessToken();
                    const result: any = await baseQueryWithReauth(
                        {
                            headers: {
                                "Content-Type": "application/json",
                                ...(TOKEN ? { "X-Auth-Token": TOKEN } : {})
                            },
                            url: `${API}/v1/favorites`,
                            method: "POST",
                            timeout: 2000,
                            body: JSON.stringify({
                                offerUuid,
                                sourceId: 1
                            })
                        },
                        api,
                        extraOptions,
                        true,
                        {
                            action: "addToFavorites",
                            args: args
                        }
                    );

                    return result.data && result.data?.data
                        ? { data: result.data.data }
                        : { error: result.error as FetchBaseQueryError };
                } catch (err) {
                    return { error: err as FetchBaseQueryError };
                }
            },
            invalidatesTags: ["Favorites"]
        }),
        removeFromFavorites: builder.mutation<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const offerUuid = args.id;
                const TOKEN = getAccessToken();
                const result: any = await baseQueryWithReauth(
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-Token": TOKEN
                        },
                        url: `${API}/v1/favorites/${offerUuid}`,
                        method: "DELETE"
                    },
                    api,
                    extraOptions
                );

                return result.data && result.data?.data
                    ? { data: result.data.data }
                    : { error: result.error as FetchBaseQueryError };
            },
            invalidatesTags: ["Favorites"]
        }),
        deleteOffer: builder.mutation<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const offerUuid = args.id;
                const TOKEN = getAccessToken();
                const result: any = await baseQueryWithReauth(
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-Token": TOKEN
                        },
                        url: `${API}/v1/offers/${offerUuid}`,
                        method: "DELETE"
                    },
                    api,
                    extraOptions
                );

                return result.meta.response.ok
                    ? { data: true }
                    : { error: result.error as FetchBaseQueryError };
            },
            invalidatesTags: ["Offers", "MyOffers"]
        }),

        getFavoritesOffers: builder.query<Car[], any>({
            queryFn: async (args, api, extraOptions) => {
                const TOKEN = getAccessToken();
                const result: any = await baseQueryWithReauth(
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-Token": TOKEN
                        },
                        url: `${API}/v1/favorites`
                    },
                    api,
                    extraOptions,
                    false
                );

                if (result.data?.data) {
                    const modifiedOffers = result.data.data.map((item: FavoriteCar) => ({
                        ...item.offer,
                        imagesList: item.offer.imagesList.map((image: ImageList) => ({
                            big: convertToSafeUrl(image.big),
                            small: convertToSafeUrl(image.small)
                        }))
                    }));
                    return { data: modifiedOffers };
                } else {
                    return { error: result.error as FetchBaseQueryError };
                }
            },
            providesTags: ["Favorites"]
        }),
        getMyOffers: builder.query<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const TOKEN = getAccessToken();
                const statusId = args.statusId;
                const page = args.page ? args.page : 1;
                let url = `${API}/v1/profiles/me/offers?limit=1000&page=${page}`;
                if (statusId) {
                    url += `&status_id=${statusId}`;
                }
                const result: any = await baseQueryWithReauth(
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-Token": TOKEN
                        },
                        url
                    },
                    api,
                    extraOptions
                );

                return result.data
                    ? {
                          data: {
                              offers: result.data.data,
                              meta: result.data.meta
                          }
                      }
                    : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["MyOffers"]
        }),
        getBanners: builder.query<any, any>({
            queryFn: async (args, api, extraOptions) => {
                const result: any = await baseQueryWithReauth(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${API}/v1/ad/banners`
                    },
                    api,
                    extraOptions,
                    false
                );

                return result.data
                    ? {
                          data: result.data
                      }
                    : { error: result.error as FetchBaseQueryError };
            },
            providesTags: ["Banners"]
        }),
        getProductPriceUsd: builder.query<number, ProductPriceUsdParams>({
            queryFn: async (args, api, extraOptions) => {
                const { currencyCode, price } = args;
                const response: any = await baseQueryWithReauth(
                    {
                        headers: { "Content-Type": "application/json" },
                        url: `${configApi.API_URL}/v1/exchange-rates/conversion/${currencyCode}/USD/${price}`
                    },
                    api,
                    extraOptions,
                    false
                );

                if (response.error) {
                    return { error: response.error as FetchBaseQueryError };
                }

                return { data: response.data.data };
            }
        }),
        updateOfferPriceUsd: builder.mutation<void, string>({
            queryFn: async (offerId, api, extraOptions) => {
                const TOKEN = getAccessToken();
                const headers = {
                    "Content-Type": "application/json",
                    "X-Auth-Token": TOKEN
                };
                const url = `${configApi.API_URL}/v1/exchange-rates/offers/${offerId}/conversion`;

                const result: any = await baseQueryWithReauth(
                    {
                        method: "POST",
                        headers,
                        url
                    },
                    api,
                    extraOptions,
                    true
                );

                return result.data
                    ? {
                          data: result.data
                      }
                    : { error: result.error };
            },
            invalidatesTags: ["Offers"]
        })
    })
});

export const transformOfferApiData = (offer: any, fixTimeZone = false): Car => {
    const brand = getCamelCaseInsensitive(offer, "brand") || getCamelCaseInsensitive(offer, "mark");
    const engine = getCamelCaseInsensitive(offer, "prepEngineType");

    const horsePower = getCamelCaseInsensitive(offer, "horsePower");
    const displacement = getCamelCaseInsensitive(offer, "displacement");
    const props = [
        { label: "Год выпуска", value: parseInt(offer.year) },
        {
            label: "Пробег",
            value: offer.mileage ? numberWithSpaces(offer.mileage) : undefined
        },
        { label: "Цвет", value: getCamelCaseInsensitive(offer, "prepColor") },
        { label: "Поколение", value: offer.generation },
        {
            label: "Тип двигателя",
            value: getCamelCaseInsensitive(offer, "prepEngineType")
        },
        {
            label: "Трансмиссия",
            value: getCamelCaseInsensitive(offer, "prepTransmission")
        },
        {
            label: "Мощность",
            value: horsePower ? `${getCamelCaseInsensitive(offer, "horsePower")} л.с.` : undefined
        },
        {
            label: "Тип кузова",
            value: getCamelCaseInsensitive(offer, "prepBodyType")
        },
        { label: "Руль", value: getCamelCaseInsensitive(offer, "prepWheel") },
        {
            label: "Привод",
            value: getCamelCaseInsensitive(offer, "prepDriveType")
        },
        { label: "ПТС", value: getCamelCaseInsensitive(offer, "prepPts") },
        {
            label: "Владельцев по ПТС",
            value: getCamelCaseInsensitive(offer, "ownersCount")
        },
        {
            label: "Объем двигателя",
            value: displacement ? `${getCamelCaseInsensitive(offer, "displacement")} л.` : undefined
        }
    ].filter((p) => p.value);

    let imagesList = offer.images_list ? JSON.parse(offer.images_list) : offer.imagesList;

    let pics =
        imagesList && imagesList.length > 0
            ? imagesList.map((image: ImageList) => ({
                  url: convertToSafeUrl(image.small),
                  bigUrl: convertToSafeUrl(image.big)
              }))
            : [];

    const cleanStr = (str: string) => {
        return str ? str : "";
    };
    const generatedDescription = `Продается ${cleanStr(
        getCamelCaseInsensitive(offer, "prepBodyType")
    )} ${cleanStr(brand)} ${cleanStr(offer.model)} ${cleanStr(offer.generation)} ${
        offer.year
    } года выпуска.`;

    const createdAt = getCamelCaseInsensitive(offer, "createdAt");

    const date1 = DateTime.fromISO(createdAt);
    const date2 = DateTime.now();

    const updated = date1
        .diff(date2, ["years", "months", "days", "hours", "minutes", "seconds"])
        .toObject();

    let updatedStr = "";

    const prepareVal = (val: number | undefined) => {
        return val ? Math.round(Math.abs(val)) : 0;
    };

    const {
        years: yearsRaw,
        months: monthRaw,
        days: daysRaw,
        hours: hoursRaw,
        minutes: minutesRaw,
        seconds: secondsRaw
    } = updated;

    const years = prepareVal(yearsRaw);
    const months = prepareVal(monthRaw) + years * 12;
    const days = prepareVal(daysRaw);
    const hours = prepareVal(hoursRaw);
    const minutes = prepareVal(minutesRaw);
    const seconds = prepareVal(secondsRaw);

    if (months > 12) {
        const years = Math.round(months / 12);
        updatedStr = `${years} ${createLabel(years, ["год", "года", "лет"])} назад`;
    } else if (months > 0) {
        updatedStr = `${months} ${createLabel(months, ["месяц", "месяца", "месяцев"])} назад`;
    } else if (days > 6) {
        const weeks = Math.round(days / 7);
        updatedStr = `${weeks} ${createLabel(weeks, ["неделя", "недели", "недель"])} назад`;
    } else if (days > 2) {
        updatedStr = `${days} ${createLabel(days, ["день", "дня", "дней"])} назад`;
    } else if (days > 1) {
        updatedStr = `вчера, ${date1.toFormat("HH:mm")}`;
    } else if (hours > 1) {
        updatedStr = `${hours} ${createLabel(hours, ["час", "часа", "часов"])} назад`;
    } else if (minutes > 1) {
        updatedStr = `${minutes} ${createLabel(minutes, ["минуту", "минуты", "минут"])} назад`;
    } else if (seconds > 10) {
        updatedStr = `${seconds} ${createLabel(seconds, ["секунду", "секунды", "секунд"])} назад`;
    } else {
        updatedStr = "только что";
    }

    const contact = getCamelCaseInsensitive(offer, "contact_person");

    const location = offer.location;
    return JSON.parse(
        JSON.stringify({
            id: offer.id,
            brandUuid: offer.brandUuid,
            modelUuid: offer.modelUuid,
            generationUuid: offer.generationUuid,
            model: offer.model,
            brand: brand,
            generation: offer.generation,
            generationNumber: getCamelCaseInsensitive(offer, "generationNumber"),
            generationName: getCamelCaseInsensitive(offer, "generationName"),
            restyleNumber: getCamelCaseInsensitive(offer, "restyleNumber"),
            title: offer.title || `${brand} ${offer.model} ${offer.year} года выпуска`,
            pics: pics,
            engine,
            price: offer.price,
            priceUsd: offer.priceUsd,
            year: offer.year,
            mileage: offer?.mileage ? numberWithSpaces(offer?.mileage?.toString()) : undefined,
            color: getCamelCaseInsensitive(offer, "prepColor"),
            transmission: getCamelCaseInsensitive(offer, "prepTransmission"),
            wheel: getCamelCaseInsensitive(offer, "prepWheel"),
            horsePower: getCamelCaseInsensitive(offer, "horsePower"),
            drive: getCamelCaseInsensitive(offer, "prepDriveType"),
            body: getCamelCaseInsensitive(offer, "prepBodyType"),
            createdAt,
            description: offer.description ? offer.description : generatedDescription,
            totalViews: offer.totalViews ?? 0,
            city: offer.city ? offer.city : undefined,
            address: offer.address ? offer.address : undefined,
            contact: contact,
            updated: updatedStr,
            props: props,
            url: offer.url,
            sourceId: offer.sourceId ?? 0,
            phone: offer.prepPhone,
            sellerType:
                getCamelCaseInsensitive(offer, "prepSellerType") === "компания"
                    ? "legal"
                    : "individual",
            ownersQnt: getCamelCaseInsensitive(offer, "ownersCount"),
            statusId: getCamelCaseInsensitive(offer, "status_id"),
            isDamaged: offer.isDamaged ?? false,
            comment: offer.comment,
            vin: offer.vin ? offer.vin.toString().toUpperCase() : undefined,
            latitude: location?.lat,
            longitude: location?.lon,
            updatedAt: offer.updatedAt ? offer.updatedAt : undefined
        })
    );
};

const transformOffersApiData = (data: any[], fixTimeZone = false): Car[] => {
    const offers: Car[] = [];

    for (let offerData of data) {
        const offer = offerData._source;
        const offerPrepared = transformOfferApiData(offer, fixTimeZone);
        offers.push(offerPrepared);
    }

    return offers;
};

/* TODO:
1. application/json
2. передавать в теле запроса данные в виде JSON
3. media_objects

*/

export const createOffer = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "offers/createOffer",
    async (payload: any, thunkAPI) => {
        try {
            const TOKEN = getAccessToken();

            const URL = `${API}/v1/offers`;
            const result: any = await baseQueryWithReauth(
                {
                    url: URL,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-token": TOKEN
                    },
                    body: payload
                },
                {
                    ...thunkAPI,
                    endpoint: URL,
                    type: "mutation"
                },
                {}
            );

            thunkAPI.dispatch(offersApi.util.invalidateTags(["MyOffers", "Offers"]));
            return result;
        } catch (err: any) {
            return thunkAPI.rejectWithValue({ message: err.message.toString() });
        }
    }
);

export const updateOffer = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "offers/updateOffer",
    async (payload: any, thunkAPI) => {
        try {
            const URL = `${API}/v1/offers/${payload.id}`;
            const TOKEN = getAccessToken();

            const result: any = await baseQueryWithReauth(
                {
                    headers: {
                        "X-Auth-Token": TOKEN
                    },
                    url: URL,
                    method: "PUT",
                    body: payload.formData
                },
                {
                    ...thunkAPI,
                    endpoint: URL,
                    type: "mutation"
                },
                {}
            );

            if (result?.data?.code !== 200) {
                toast.error("Ошибка при обновлении объявления", {
                    autoClose: 3000
                });
            }

            thunkAPI.dispatch(offersApi.util.invalidateTags(["MyOffers", "Offers"]));
            return result;
        } catch (err: any) {
            return thunkAPI.rejectWithValue({ message: err.message.toString() });
        }
    }
);

export const validateOffer = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "offers/validateOffer",
    async (payload: number, thunkAPI) => {
        try {
            const URL = `${API}/v1/offers/${payload}/verify`;
            const TOKEN = getAccessToken();

            const result: any = await baseQueryWithReauth(
                {
                    headers: {
                        "X-Auth-Token": TOKEN,
                        "Content-Type": "application/json"
                    },
                    url: URL,
                    method: "GET"
                },
                {
                    ...thunkAPI,
                    endpoint: URL,
                    type: "query"
                },
                {}
            );

            return result;
        } catch (err: any) {
            return thunkAPI.rejectWithValue({ message: err.message.toString() });
        }
    }
);

export const publishOffer = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "offers/validateOffer",
    async (payload: number, thunkAPI) => {
        try {
            const URL = `${API}/v1/offers/${payload}/publish`;
            const TOKEN = getAccessToken();

            const result: any = await baseQueryWithReauth(
                {
                    headers: {
                        "X-Auth-Token": TOKEN,
                        "Content-Type": "application/json"
                    },
                    url: URL,
                    method: "POST"
                },
                {
                    ...thunkAPI,
                    endpoint: URL,
                    type: "query"
                },
                {}
            );

            return result;
        } catch (err: any) {
            return thunkAPI.rejectWithValue({ message: err.message.toString() });
        }
    }
);

export const uploadFile = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "offers/uploadFile",
    async (payload: any, thunkAPI) => {
        const { rejectWithValue, getState, dispatch, extra } = thunkAPI;
        try {
            const URL = `${API}/v1/media_object`;
            const TOKEN = getAccessToken();

            const result: any = await baseQueryWithReauth(
                {
                    headers: {
                        "X-Auth-Token": TOKEN
                    },
                    url: URL,
                    method: "POST",
                    body: payload
                },
                {
                    ...thunkAPI,
                    endpoint: URL,
                    type: "mutation"
                },
                {}
            );

            return result;
        } catch (err: any) {
            return rejectWithValue({ message: err.message.toString() });
        }
    }
);

export const {
    useGetOffersQuery,
    useGetRecommendedOffersQuery,
    useGetOfferQuery,
    useAddToFavoritesMutation,
    useRemoveFromFavoritesMutation,
    useDeleteOfferMutation,
    useGetFavoritesOffersQuery,
    useGetMyOffersQuery,
    useGetBannersQuery,
    useGetProductPriceUsdQuery,
    useUpdateOfferPriceUsdMutation,
    endpoints: { getOffer, getOffers }
} = offersApi;